@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.1fr;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: "Rubber";
}

.i6 {
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
}

.North-Points {
  grid-area: 1 / 1 / 2 / 2;
}

.South-Points {
  grid-area: 2 / 1 / 3 / 2;
}

.East-Points {
  grid-area: 3 / 1 / 4 / 2;
}

.West-Points {
  grid-area: 4 / 1 / 5 / 2;
}

.North-Points-Value {
  grid-area: 1 / 2 / 2 / 3;
}

.East-point-value {
  grid-area: 2 / 2 / 3 / 3;
}

.South-Points-Value {
  grid-area: 2 / 2 / 3 / 3;
}

.East-Points-Value {
  grid-area: 3 / 2 / 4 / 3;
}

.West-Points-Value {
  grid-area: 4 / 2 / 5 / 3;
}

.Rubber {
  grid-area: Rubber;
}

/* .testtext {
  margin-top: 0px;
} */
